import React from 'react'

import imgPayment from '../images/payment.svg'

import SectionList from './SectionList'

const SectionFour = () => (
    <section className="hero is-fullheight is-primary">
        <div className="hero-body">
            <div className="container">
                <div className="columns is-vcentered is-variable is-8">
                    <div className="column is-hidden-desktop">
                        <figure className="image ">
                            <img src={imgPayment} alt="bookinski-logo" />
                        </figure>
                    </div>
                    <div className="column is-three-fifths">
                        <SectionList
                            title="Na odmor ponesi dobro raspoloženje!"
                            subtitle="Umesto gotovine, na odmor ponesi dobro raspoloženje. Pružamo ti:"
                            items={[
                                'Transparentne cene svih usluga i opreme',
                                'Plaćanje online uz maksimalnu bezbednost svake transakcije',
                            ]}
                        />
                    </div>
                    <div className="column is-hidden-touch">
                        <figure className="image ">
                            <img src={imgPayment} alt="bookinski-logo" />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default SectionFour
