import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'

export default () => (
    <StaticQuery
        query={graphql`
            query helmetQuery {
                site {
                    siteMetadata {
                        title
                        description
                        keywords
                        siteUrl
                        imageUrl
                        fbId
                        siteName
                    }
                }
            }
        `}
        render={({ site }) => (
            <Helmet>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
                />
                <meta name="description" content={site.siteMetadata.description} />
                <meta name="keywords" content={site.siteMetadata.keywords} />
                <title>{site.siteMetadata.title}</title>
                <html lang="en" />
                {/* Google / Search Engine Meta Tags */}
                <meta itemProp="description" content={site.siteMetadata.description} />
                <meta itemProp="image" content={site.siteMetadata.imageUrl} />
                
                {/* OG props */}
                <meta property="og:url" content={site.siteMetadata.siteUrl} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={site.siteMetadata.title} />
                <meta property="og:description" content={site.siteMetadata.description} />
                <meta property="og:image" content={site.siteMetadata.imageUrl} />
                <meta property="og:site_name" content={site.siteMetadata.siteName} />
                <meta property="fb:app_id" content={site.siteMetadata.fbId} />
            </Helmet>
        )}
    />
)
