import React, { useState, useRef } from 'react'
import axios from 'axios'

const EmailInput = () => {
    const inputRef = useRef()

    const [loading, setLoading] = useState(false)
    const [isSubscribed, setIsSubscribed] = useState(false)
    const [isInstructor, setIsInstructor] = useState(false)
    const [error, setError] = useState('')
    const [input, setInput] = useState('')

    const handleSubscribe = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            await axios.post(process.env.BOOKINSKI_API_URL, {
                email: input,
                subscribed: true,
                instructor: isInstructor,
            })
            setIsSubscribed(true)
        } catch (error) {
            console.log(error.response)

            let errorMessage = 'Došlo je do greške, pokušajte ponovo.'

            if (error?.response?.status === 500) {
                errorMessage = 'Ups! Čini nam se da ste već prijavljeni'
            }

            setError(errorMessage)

            setTimeout(() => {
                setError('')
            }, 3000)
        } finally {
            setLoading(false)
        }
    }

    const handleInput = (e) => {
        setInput(e.target.value)
    }

    const handleCheckbox = (e) => {
        setIsInstructor((prev) => !prev)
    }

    const validateInput = (e) => {
        var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        )
        return !pattern.test(input)
    }

    return (
        <div className="columns is-variable is-0-mobile">
            <div className="column is-two-thirds is-full-mobile is-gapless-mobile">
                <div className="field">
                    <div className="control">
                        <input
                            ref={inputRef}
                            className="input is-primary is-medium"
                            type="email"
                            value={input}
                            disabled={isSubscribed}
                            onChange={handleInput}
                            placeholder="Unesi e-mail adresu"
                        />
                    </div>
                    <div className="control">
                        <label className="checkbox">
                            <input
                                checked={isInstructor}
                                disabled={isSubscribed}
                                onChange={handleCheckbox}
                                type="checkbox"
                            />{' '}
                            Prijavi me kao ski/snowboard instruktora
                        </label>
                    </div>
                    {isSubscribed && (
                        <div className="has-text-success">
                            Uspešno ste se prijavili. U kontaktu smo :)
                        </div>
                    )}
                    <div className="has-text-danger">{error ? error : ' '}</div>
                </div>
            </div>
            <div className="column is-full-mobile is-one-quarter-desktop is-one-third-desktop">
                {!isSubscribed && (
                    <button
                        className={`button is-info is-medium is-fullwidth ${
                            loading ? 'is-loading' : ''
                        }`}
                        onClick={handleSubscribe}
                        disabled={loading || validateInput() || !!error}>
                        PRIJAVI SE
                    </button>
                )}
            </div>
        </div>
    )
}

export default EmailInput
