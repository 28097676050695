import React from 'react'
import Logo from './Logo'
import EmailInput from './EmailInput'

import imgConfete from '../images/confete.svg'

const SectionFive = () => (
    <section className="hero is-fullheight hero-bottom-img">
        <div className="hero-head">
            <div className="container has-text-centered mt-6">
                <Logo />
            </div>
        </div>
        <div className="hero-body">
            <div className="container">
                <p className="title has-text-weight-bold mb-6 has-text-centered">
                    BOOKINSKI JE TU ZA SVE TVOJE REZERVACIJE
                </p>

                <div className="columns">
                    <div className="column is-half is-offset-one-quarter">
                        <EmailInput />
                    </div>
                </div>

                <div className="content has-text-centered">
                    <figure className="image is-inline-block ">
                        <img src={imgConfete} alt="bookinski-logo" />
                    </figure>
                </div>

                <div className="content has-text-centered">
                    <p className="is-size-4	 has-text-weight-bold	my-3">I NE ZABORAVI</p>
                    <p className="subtitle">Bookiranje preko nas je bez provizije</p>
                    <a href="mailto:hello@bookinski.com">hello@bookinski.com</a>
                </div>
            </div>
        </div>
        <div className="hero-foot bg-dark">
            <div className="container is-primary py-3 has-text-white">
                <p>&copy; {new Date().getFullYear()} Bookinski</p>
            </div>
        </div>
    </section>
)

export default SectionFive
