import React from 'react'

import EmailInput from './EmailInput'
import InfoCard from './InfoCard'
import Navbar from './NavBar'
import SectionList from './SectionList'

const SectionOne = () => (
    <section className="hero is-fullheight hero-img">
        <Navbar />
        <div className="hero-body">
            <div className="container">
                <div className="columns is-vcentered is-variable is-8">
                    <div className="column is-three-fifths">
                        <SectionList
                            dark
                            title="Osvoji planinu!"
                            subtitle="Bookinski vodič ti pomaže da besplatno, brzo i jednostavno pronađeš po svojoj meri:"
                            items={[
                                'Ski/snowboard instruktora ili školu',
                                'Ski opremu',
                                'Ski servis',
                            ]}
                        />

                        <h2 className="subtitle is-4 ">
                            Nađi sve na jednom mestu i uštedi vreme
                        </h2>
                        <EmailInput />
                        <h2 className="subtitle is-4  mb-6">
                            pišemo ti čim Bookinski bude na Google Play-u i Apple Store-u
                        </h2>
                        <InfoCard />
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default SectionOne
