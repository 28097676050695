import React from 'react'

const SectionList = ({ title, subtitle, items, dark = false }) => (
    <>
        <p className="title is-3 is-spaced mb-6 is-family-monospace has-text-weight-bold is-uppercase">
            {title}
        </p>
        <p className="subtitle is-4  mb-6">{subtitle}</p>
        <div className="content is-large">
            <ul className={`items${dark ? ' dark' : ' light'}`}>
                {items.map((item, index) => (
                    <li className="check" key={index}>
                        <span>{item}</span>
                    </li>
                ))}
            </ul>
        </div>
    </>
)

export default SectionList
