import React from 'react'

import imgLogo from '../images/logo-black.svg'

const Logo = () => {
    return (
        <img src={imgLogo} alt="bookinski-logo" className="image logo is-inline-block" />
    )
}

export default Logo
