import React from "react";

import Logo from "./Logo";

const Navbar = () => (
    <div className="hero-head">
        <nav className="navbar">
            <div className="container">
                <div className="navbar-brand pl-4">
                    <Logo />
                </div>
            </div>
        </nav>
    </div>
);

export default Navbar;
