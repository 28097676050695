import React from 'react'

// import './style.scss'

import imgSkiRent from '../images/instructor.svg'
import SectionList from './SectionList'

const SectionThree = () => (
    <section className="hero is-fullheight is-info">
        <div className="hero-body">
            <div className="container">
                <div className="columns is-vcentered is-variable is-8">
                    <div className="column">
                        <figure className="image ">
                            <img src={imgSkiRent} alt="bookinski-logo" />
                        </figure>
                    </div>
                    <div className="column is-three-fifths">
                        <SectionList
                            title="Isplaniraj svoju zimsku avanTURU!"
                            subtitle="U samo par klikova uz pomoć Bookinskog:"
                            items={[
                                'Zakaži termin kod odgovarajućeg ski/snowboard instruktora ili škole',
                                'Iznajmi opremu u skladu sa svojim skijaškim veštinama',
                                'Rezerviši servis svoje ski opreme',
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default SectionThree
