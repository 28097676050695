import React from 'react'

// import './style.scss'

import imgSkiRent from '../images/ski-rent.svg'
import SectionList from './SectionList'

const SectionTwo = () => (
    <section className="hero is-fullheight is-primary">
        <div className="hero-body">
            <div className="container">
                <div className="columns is-vcentered is-variable is-8">
                    <div className="column is-hidden-desktop">
                        <figure className="image ">
                            <img src={imgSkiRent} alt="bookinski-logo" />
                        </figure>
                    </div>
                    <div className="column is-three-fifths">
                        <SectionList
                            title="Ovladaj stazama znanjem!"
                            subtitle="Na planinu dođi pripremljen i naoružan informacijama:"
                            items={[
                                'Saznaj sve o ski instruktorima i ski školama (licence, dostupnost, cene…)',
                                'Informiši se o ski rentalima i prodavnicama (radno vreme, ponuda, lokacija…)',
                                'Uči na tuđim iskustvima - pročitaj komentare, savete i utiske drugih skijaša i snowboardera',
                            ]}
                        />
                    </div>
                    <div className="column is-hidden-touch">
                        <figure className="image ">
                            <img src={imgSkiRent} alt="bookinski-logo" />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default SectionTwo
