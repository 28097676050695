import React from "react";

import imgConfete from "../images/confete.svg";

const InfoCard = () => (
    <div className="card is-info-card">
        <div
            style={{
                display: "flex",
                alignItems: "center",
                maxHeight: "100%",
                height: "100%",
            }}
        >
            <figure
                style={{
                    marginLeft: "12px",
                    marginRight: "6px",
                    marginTop: "-12px",
                }}
            >
                <img src={imgConfete} alt="info-logo" />
            </figure>
            <p className="subtitle">
                <strong>VAŽNO:</strong> Bookiranje preko nas je bez provizije
            </p>
        </div>
    </div>
);

export default InfoCard;
